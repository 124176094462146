<script>
export default {
  name: 'OldApplications',
  data () {
    return {
      header: [
        {
          text: this.$t('applications.header:name'),
          value: 'name',
          width: '60%'
        },
        {
          text: this.$t('applications.header:status'),
          value: 'status'
        }
      ]
    }
  },
  computed: {
    oldApplications () {
      return this.$store.getters.getUserOldApplications || []
    }
  },
  methods: {
    loadOldApplications () {
      this.$store.dispatch('attemptGetUserOldApplications')
    }
  },
  created () {
    this.loadOldApplications()
  }
}
</script>
<template>
  <div class="old-applications--container">
    <div class="old-applications--actions">
      <p class="body--1 bold pl-4">{{ $t('closed.applications:support.text') }}</p>
    </div>
    <v-data-table
      class="applications-table--wrapper"
      :headers="header"
      :items="oldApplications"
      :items-per-page="99999"
      disable-sort
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
        <tr>
          <th v-for="h in headers" :key="h.value" :width="h.width" :class="{'text-center': h.align === 'center'}">
            <span class="table-header">{{h.text}} </span>
          </th>
        </tr>
        </thead>
      </template>
      <template
        v-if="oldApplications.length > 0"
        v-slot:body
      >
        <tbody>
        <tr
          v-for="({ position }, index) in oldApplications"
          :key="index"
          class="application-table--line"
        >
          <td class="text-left overflow-text">
            <div class="applications--user-info">
              <div class="image-wrapper">
                <v-img class="applications--user-picture" :src="position.company.logo || defaultAvatarUrl('profile')"></v-img>
              </div>
              <div class="applications-name--wrapper">
                <span class="applications--user-name bold">{{ position.title }}</span>
                <span class="applications--company-name">{{ position.company.name }}</span>
              </div>
            </div>
          </td>
          <td class="text-left">{{ $t('old.application.not.finished') }}</td>
        </tr>
        </tbody>
      </template>
      <template
        v-if="oldApplications.length === 0"
        v-slot:no-data
      >
        {{ $t('applications.modal.table:no.applications.found') }}
      </template>
    </v-data-table>
  </div>
</template>
<style lang="scss">
.old-applications--container {
  .old-applications--actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .order-by--select {
      padding-top: 20px;
      max-width: 215px;
    }
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 60px;
  }
  .applications-table--wrapper {
    .applications--user-info {
      display: flex;
      align-items: center;
      .applications--user-picture {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .applications-name--wrapper {
        display: flex;
        flex-direction: column;
        span {
          margin: 0;
          line-height: 17px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
